<template>
    <div>
        <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    // 向子组件传递方法
    provide () {
        return {
            reload: this.reload
        }
    },
    data () {
        return {
            isRouterAlive: true,
            commonClass: ''
        }
    },
    computed: {
        ...mapState(['isTabbarShow'])
    },
    methods: {
        // 路由页面重新加载
        reload () {
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        }
    }
}
</script>
<style lang="scss">
* {
    margin: 0;
    padding: 0;
}
body {
    width: 100%;
    font-size: $defaultFontSize;
    background-color: #eee;
}
ul {
    list-style: none;
}
</style>
