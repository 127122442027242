const storage = {
    setLoginData (jsonStr) {
        localStorage.setItem('login_data', jsonStr)
    },
    getLoginData () {
        return localStorage.getItem('login_data')
    },
    getToken () {
        const jsonStr = this.getLoginData()
        if (!jsonStr) { return '' }
        const jsonData = JSON.parse(jsonStr)
        return jsonData.token
    },
    isAdmin () {
        const jsonStr = this.getLoginData()
        if (!jsonStr) { return false }
        const jsonData = JSON.parse(jsonStr)
        return String(jsonData.is_admin) === '1'
    },
    clearLoginData () {
        localStorage.removeItem('login_data')
    },
    isProceduresProfit () {
        const jsonStr = this.getLoginData()
        if (!jsonStr) { return false }
        const jsonData = JSON.parse(jsonStr)
        return String(jsonData.is_procedures_profit) === '1'
    },
    isServiceProfit () {
        const jsonStr = this.getLoginData()
        if (!jsonStr) { return false }
        const jsonData = JSON.parse(jsonStr)
        return String(jsonData.is_service_profit) === '1'
    }
}
export default storage
