import Vue from 'vue'
import Vuex from 'vuex'
import service from '@/utils/service'
import { Toast } from 'vant'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 是否显示底部导航栏
        isTabbarShow: false,
        // 存储省市区列表
        areaList: {},
        // 存储省市区id
        areaIds: {},
        // 行业数据
        industryList: [],
        industryIds: [],
        // 商户名称
        companyName: '',
        requestLoding: true,
        // 商户页缓存
        merchantsCache: null
    },
    getters: {
    },
    // 同步执行
    mutations: {
        showTabbar (state) {
        state.isTabbarShow = true
        },
        hideTabbar (state) {
        state.isTabbarShow = false
        },
        setAreaData (state, areaList) {
            state.areaList = areaList
        },
        setAreaIds (state, areaIds) {
            state.areaIds = areaIds
        },
        setIndustryData (state, industryList) {
            state.industryList = industryList
        },
        setIndustryIds (state, industryIds) {
            state.industryIds = industryIds
        },
        setCompanyName (state, name) {
            state.companyName = name
        },
        setRequestLoding (state, value) {
            state.requestLoding = value
        },
        setMerchantsCache (state, value) {
            state.merchantsCache = value
        }
    },
    // 异步执行
    actions: {
        // 获取省市区和行业数据列表
        getOpenAccountData (store) {
            return service.get('distribution/merchant/get-index-data').then(
                res => {
                    if (res.data.code === 0) {
                        let areaList = {
                            province_list: {},
                            city_list: {},
                            county_list: {}
                        }
                        let areaIds = {}
                        let industryIds = []
                        let industryList = []

                        // 省市区
                        // 手动添加北京二级
                        res.data.data.areaList.push({
                            id: 1001,
                            pid: 2,
                            district_name: '北京',
                            type: 2,
                            wechat_address_code: '110100'
                        })
                        res.data.data.areaList.forEach(item => {
                            // 存储地区码对应的数据表ID
                            areaIds[item.wechat_address_code] = item.id
                            // 分级地区码
                            if (item.type === 1) {
                                areaList.province_list[item.wechat_address_code] = item.district_name
                            } else if (item.type === 2) {
                                areaList.city_list[item.wechat_address_code] = item.district_name
                            } else if (item.type === 3) {
                                areaList.county_list[item.wechat_address_code] = item.district_name
                            }
                        })
                        // 数据存储
                        store.commit('setAreaData', areaList)
                        store.commit('setAreaIds', areaIds)
                        areaList = null
                        areaIds = null
                        // 行业
                        res.data.data.industryList.forEach(item => {
                            // 存储行业对应的数据表ID
                            industryIds.push(item.id)
                            industryList.push(item.industry)
                        })
                        store.commit('setIndustryData', industryList)
                        store.commit('setIndustryIds', industryIds)
                        industryIds = null
                        industryList = null
                    }
                },
                err => {
                    Toast.fail(err.data.message)
                }
            )
        }
    },
    modules: {
    }
})
