import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView'
import storage from '@/utils/localStorge'

Vue.use(VueRouter)
const routes = [
    {
        path: '/entrance',
        component: () => import('../views/EntranceView'),
        children: [
            {
                path: '/entrance/login',
                component: () => import('../views/entrance/LoginTab'),
                // 路由拦截
                meta: {
                    isNext: true
                }
            },
            {
                path: '/entrance/register',
                component: () => import('../views/entrance/RegisterTab'),
                // 路由拦截
                meta: {
                    isNext: true
                }
            },
            // 重定向默认地址
            {
                path: '/entrance',
                redirect: '/entrance/login'
            }
        ],
        // 路由拦截
        meta: {
            isNext: true
        }
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView
        // children: [
        //     {
        //         path: '/home/un-submit',
        //         component: UnSubmit
        //     },
        //     {
        //         path: '/home/un-exam',
        //         component: () => import('../views/home/UnExam')
        //     },
        //     {
        //         path: '/home/reject',
        //         component: () => import('../views/home/RejectList')
        //     },
        //     {
        //         path: '/home/adopt',
        //         component: () => import('../views/home/AdoptList')
        //     },
        //     // 重定向默认地址
        //     {
        //         path: '/home',
        //         redirect: '/home/un-submit'
        //     }
        // ]
        // beforeEach: (to, from, net) => {
        //     //局部拦截
        //     if (localStorage.getItem('authorization')) {
        //         next()
        //     } else {
        //         next('/entrance')
        //     }
        // }
    },
    {
        path: '/data',
        component: () => import('../views/DataView')
    },
    {
        path: '/merchants/:time_start?/:time_end?',
        component: () => import('../views/MerchantsView')
    },
    {
        path: '/merchant-add',
        component: () => import('../views/merchant/AddView')
    },
    {
        path: '/merchant-edit/:id',
        component: () => import('../views/merchant/EditView')
    },
    {
        path: '/merchant-login-logs/:company_id',
        component: () => import('../views/merchant/LoginLogsView')
    },
    {
        path: '/merchant-open-pay/:id/:company_id',
        component: () => import('../views/merchant/OpenPayView')
    },
    {
        path: '/shops/:company_id',
        component: () => import('../views/shop/ShopsView')
    },
    {
        path: '/shop-add/:company_id',
        component: () => import('../views/shop/AddView')
    },
    {
        path: '/shop-edit/:shop_id/:company_id',
        component: () => import('../views/shop/EditView')
    },
    {
        path: '/module-setting/:shop_id/:company_id',
        component: () => import('../views/shop/ModuleSettingView')
    },
    {
      path: '/fubei-setting/:shop_id/:company_id',
      component: () => import('../views/shop/FubeiSettingView')
    },
    {
        path: '/merchant-module/:shop_id/:company_id',
        component: () => import('../views/shop/ModuleView')
    },
    {
        path: '/users/:company_id',
        component: () => import('../views/user/UsersView')
    },
    {
        path: '/user-add/:company_id',
        component: () => import('../views/user/AddView')
    },
    {
        path: '/user-edit/:user_id/:company_id',
        component: () => import('../views/user/EditView')
    },

    {
        path: '/my',
        component: () => import('../views/MyView')
    },
    {
        path: '/my-recharge',
        component: () => import('../views/my/RechargeView')
    },
    {
        path: '/capital-history',
        component: () => import('../views/my/CapitalHistoryView')
    },
    {
        path: '/change-pwd',
        component: () => import('../views/my/ChangePwdView')
    },
    {
        path: '/staffs',
        component: () => import('../views/staff/StaffsView')
    },
    {
        path: '/staff/:id',
        component: () => import('../views/staff/DetailView')
    },
    {
        path: '/staff-edit/:id',
        component: () => import('../views/staff/EditView')
    },
    {
        path: '/staff-add',
        component: () => import('../views/staff/AddView')
    },
    // 重定向默认地址
    {
        path: '*',
        redirect: '/home'
    }
]

const router = new VueRouter({
//   mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

// 全局拦截
router.beforeEach((to, from, next) => {
    // 登陆 | 注册
    if (to.meta.isNext) {
        next()
    } else {
        if (storage.getToken()) {
            next()
        } else {
            next('/entrance/login')
            // next({
            //     path: 'login',
            //     query: {redirect: to.fullPath}
            // })
        }
    }
})

export default router
