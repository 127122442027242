<template>
    <div>
        <div class="banner">
            <div class=banner-left>
                <div class="banner-title">
                    <div class="float-left icon">&nbsp;</div>
                    <div class="float-left"><span>我的资产</span></div>
                </div>
                <div class="float-clear"></div>
                <div class="left-data">
                    <div class="item-row">
                        <div class="item">
                            <div>{{companyCounts[0]}}</div>
                            <div class="title">付费</div>
                        </div>
                        <div class="item">
                            <div>{{companyCounts[1]}}</div>
                            <div class="title">体验</div>
                        </div>
                    </div>
                    <div class="item-row">
                        <div class="item">
                            <div>{{companyCounts[2]}}</div>
                            <div class="title">续费提醒</div>
                            </div>
                        <div class="item">
                            <div>{{companyCounts[3]}}</div>
                            <div class="title">体验到期</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-right">
                <div class="circle-out"></div>
                <div class="circle-in"></div>
                <div class="right-data">
                    <div class="item border-bottom">
                        <div class="item-one">
                            <div class="title">余额</div>
                            <div>{{balance}}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-one">
                        <div class="title">企业数</div>
                        <div>{{companyCount}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-up">
            <div class="notice">
                <div class="notice-body">
                    <div class="icon"><van-icon name="volume-o" /></div>
                    <div class="text" @click="copyWechat">代理商扶持请加微信guanqilai01</div>
                </div>
            </div>
            <div class="amount">
                <div class="item border">
                    <div>{{allPayAdmount}}</div>
                    <div class="title">支付金额</div>
                </div>
                <div class="item" v-if="isProceduresProfit">
                    <div>{{allServiceFee}}</div>
                    <div class="title">手续费分润</div>
                </div>
            </div>

        </div>
      <div class="content-up mt10" v-if="isAdmin">
        <div class="header-title">
          <div class="float-left icon">&nbsp;</div>
          <div class="float-left"><span>当前身份：</span><span>{{isRequestAdmin ? '管理员' : '业务员'}}</span></div>
          <div class="float-right">
            <van-button round type="info" size="small" @click="changeAdmin">点击切换</van-button>
          </div>
          <div class="float-clear"></div>
        </div>
      </div>
        <div class="content-down">
            <div class="title">
                <div class="header-title">
                    <div class="float-left icon">&nbsp;</div>
                    <div class="float-left"><span>数据看榜</span></div>
                    <div class="float-right">
                        <div class="float-left date mr20" @click="showBeginDate = true">{{beginDate}}</div>
                        <van-calendar v-model="showBeginDate" @confirm="onConfirmBeginDate" :min-date="minDate" :max-date="maxDate" :default-date="defaultBeginDate"/>
                        <div class="float-left to mr20">-</div>
                        <div class="float-left date" @click="showEndDate = true">{{endDate}}</div>
                        <van-calendar v-model="showEndDate" @confirm="onConfirmEndDate" :min-date="minDate" :max-date="maxDate" :default-date="defaultEndDate"/>
                        <div class="float-clear"></div>
                    </div>
                    <div class="float-clear"></div>
                </div>
            </div>
            <div class="data">
                <div class="item-row">
                    <div class="item up">
                        <div class="item-one" @click="linkTo('/merchants/'+beginDate+'/'+endDate)">
                            <div class="item-one-left">
                                <div>{{newCompanyCount}}</div>
                                <div class="title">新增企业</div>
                            </div>
                            <div class="item-one-right">
                                <img src="@/assets/imgs/home_up_l.png" width="30px">
                            </div>
                        </div>
                    </div>
                    <div class="item up">
                        <div class="item-one">
                            <div class="item-one-left">
                                <div>{{companyShopChargeDate}}</div>
                                <div class="title">付费门店</div>
                            </div>
                            <div class="item-one-right">
                                <img src="@/assets/imgs/home_up_r.png" width="30px">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-row">
                    <div class="item down">
                        <div class="item-one">
                            <div class="item-one-left">
                                <div>{{allMoneyDate}}</div>
                                <div class="title">支付金额</div>
                            </div>
                            <div class="item-one-right">
                                <img src="@/assets/imgs/home_down_l.png" width="30px">
                            </div>
                        </div>
                    </div>
                    <div class="item down" v-if="isServiceProfit">
                        <div class="item-one">
                            <div class="item-one-left">
                                <div>{{serviceAmountDate}}</div>
                                <div class="title">手续费分润</div>
                            </div>
                            <div class="item-one-right">
                                <img src="@/assets/imgs/home_up_l.png" width="30px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add">
            <div class="button">
                <van-button type="info" size="large" icon="plus" round @click="linkTo('/merchant-add')"></van-button>
            </div>
        </div>
        <tab-bar></tab-bar>
    </div>
</template>
<script>
import service from '@/utils/service'
import TabBar from '@/components/TabBar'
import storege from '@/utils/localStorge'
export default {
    // mixins: [tabBarDisplay],
    components: {
        TabBar
    },
    data () {
        return {
            isRequestAdmin: false,
            isAdmin: 0,
            searchValue: '',
            balance: '0.00',
            companyCount: '0',
            newCompanyCount: '0',
            companyShopChargeDate: '0',
            companyCounts: [0, 0, 0, 0],
            unActiveUserConut: '0',
            beginDate: '',
            showBeginDate: false,
            endDate: '',
            showEndDate: false,
            minDate: new Date(new Date().getFullYear() - 1, 0, 1),
            maxDate: new Date(new Date()),
            isProceduresProfit: false,
            isServiceProfit: false,
            allPayAdmount: 0,
            allServiceFee: 0,
            allMoneyDate: 0,
            serviceAmountDate: 0
        }
    },
    created () {
        // 商户页缓存晴空
        this.$store.commit('setMerchantsCache', null);
        this.initDate();
        // 统计数据
        this.getIndexData(this.getRangeIndexData)

        // 时间段数据
        // this.getRangeIndexData()
    },
    computed: {
        defaultBeginDate () {
            if (this.beginDate) {
                return new Date(this.beginDate.replace(/-/g, '/'))
            }
            return []
        },
        defaultEndDate () {
            if (this.endDate) {
                return new Date(this.endDate.replace(/-/g, '/'))
            }
            return []
        }
    },
    methods: {
        changeAdmin(){
          this.isRequestAdmin = !this.isRequestAdmin;
          // 统计数据
          this.getIndexData(this.getRangeIndexData)
        },
        initDate () {
            const date = new Date()
            this.endDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            const curTime = date.getTime()
            const halfYear = 365 / 2 * 24 * 3600 * 1000
            const pastResult = curTime - halfYear
            const pastDate = new Date(pastResult)
            this.beginDate = pastDate.getFullYear() + '-' + (pastDate.getMonth() + 1) + '-' + pastDate.getDate()

            // 相关权限
            this.isProceduresProfit = storege.isProceduresProfit()
            this.isServiceProfit = storege.isServiceProfit()
        },

        getIndexData (func) {
            let isRequestAdmin = this.isRequestAdmin ? 1 : 0;
            service.get('distribution/index/get-index-data?is_request_admin=' + isRequestAdmin).then(
                res => {
                    if (res.data.code === 0) {
                        this.balance = res.data.data.balance
                        this.companyCount = res.data.data.company_count
                        this.companyCounts = res.data.data.company_counts
                        this.unActiveUserConut = res.data.data.un_active_user_count
                        this.allServiceFee = res.data.data.all_service_fee
                        this.allPayAdmount = res.data.data.all_pay_amount
                        this.isAdmin = res.data.data.is_admin

                       func && func();
                    } else {
                        this.$toast(res.data.message)
                    }
                },
                err => {
                    this.$toast(err)
                }
            )
        },
        getRangeIndexData () {
            let isRequestAdmin = this.isRequestAdmin ? 1 : 0;
            service.get(`distribution/index/get-range-index-data?begin_time=${this.beginDate}&end_time=${this.endDate}&is_request_admin=${isRequestAdmin}`).then(
            res => {
                if (res.data.code === 0) {
                    this.newCompanyCount = res.data.data.new_company_count;
                    this.allMoneyDate = res.data.data.pay_amount_date;
                    this.serviceAmountDate = res.data.data.service_amount_date;
                    this.companyShopChargeDate = res.data.data.company_shop_charge_date;
                } else {
                    this.$toast(res.data.message)
                }
            },
            err => {
                this.$toast(err)
            }
        )
        },
        onSearch () {
            console.log('search')
        },
        onScan () {
            this.$toast('onScan')
        },
        addMerchant () {
            this.$router.push('/merchant-add')
        },
        linkTo (to) {
            this.$router.push(to)
        },
        formatDate (date) {
            if (date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            } else {
                return '';
            }
        },
        onConfirmBeginDate (date) {
            this.showBeginDate = false;
            this.beginDate = this.formatDate(date);
            this.getRangeIndexData()
        },
        onConfirmEndDate (date) {
            this.showEndDate = false;
            this.endDate = this.formatDate(date);
            this.getRangeIndexData()
        },
        copyWechat(){
          //创建input标签
          var input = document.createElement('input')
          //将input的值设置为需要复制的内容
          input.value = 'guanqilai01';
          //添加input标签
          document.body.appendChild(input)
          //选中input标签
          input.select()
          //执行复制
          document.execCommand('copy')
          //成功提示信息
          this.$toast('微信号复制成功');
          //移除input标签
          document.body.removeChild(input)
        }
    }
}
</script>
<style lang="scss" scoped>
.banner {
    background-image: linear-gradient(to right top, #56b7fe,#3589fd);
    border-top-left-radius:30px;
    border-top-right-radius:30px;
    height: 21.875rem;
    color:white;
    .title {
        font-size: 12px;
    }

    display: flex;
    .banner-left {
        padding-top: 1.5625rem;
        padding-left: 1.25rem;
        flex:1;
        .banner-title {
            margin-top: .9375rem;
            .icon {
                width: .625rem;
                background-color:white;
                margin-right:.625rem;
                margin-top: 4px;
                height: 14px;
            }
        }
        .left-data {
            display: flex;
            flex-direction: column;
            height:15.625rem;
            .item-row {
                flex:1;
                margin-top: 3rem;
                text-align: center;
                display:flex;
                .item {
                    flex:1;
                }
            }
        }
    }
    .banner-right {
        flex:1;
        .circle-out{
            margin:0 auto;
            margin-top:1.875rem;
            width: 14rem;
            height: 14rem;
            border-radius: 50%;
            background: none;
            border: 1.875rem solid white;
            opacity:0.5;
        }
        .circle-in{
            top:.625rem;
            margin:-17.1rem auto;
            width: 15rem;
            height: 15rem;
            background: none;
            border: .75rem solid white;
            border-radius: 50%;
            opacity:0.7;
        }
        .right-data {
            margin: 0 auto;
            margin-top: 1.875rem;
            width: 14rem;
            height: 14rem;
            text-align: center;
            .item {
                height: 7rem;
                display: table;
                width:100%;
                .item-one{
                    // padding-top:1rem;
                    display: table-cell;
                    vertical-align: middle;
                }
            }
            .border-bottom {
                border-bottom: .125rem dashed white;
            }
        }
    }
}
.content-up {
    background-color: white;
}
.notice {
    padding:10px;
    .notice-body{
        border-radius: 5px;
        background-color:#eee;
        padding-left:1.25rem;
        .icon {
            margin-right: .625rem;
            padding-top:.25rem;
            font-size: 20px;
            color: $themeColor;
            display: inline-block;
            vertical-align: middle;
        }
        .text {
            font-size: 12px;
            line-height: 30px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
.amount {
    padding:2rem 0;
    display: flex;
    .item {
        text-align: center;
        flex: 1;
        .title {
            font-size: 12px;
            color:grey;
        }
    }
    .border {
        border-right: 0.25rem #eee solid ;
    }
}
.content-down {
    margin-top:.625rem;
    background-color: white;
    padding-bottom: 1.25rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-bottom:6.125rem;
    box-shadow:0 10px 10px -15px #000;
    .date {
        background-color: #eee;
        padding:5px 20px;
        border-radius: 10px;
        color:#999;
        font-size: 12px;
    }
    .to {
        color:#999;
    }
}
.header-title {
    padding: 2.5rem 1.25rem;
    .icon {
        width: .625rem;
        background-color:$themeColor;
        margin-right:.625rem;
        margin-top: 4px;
        height: 14px;
    }
}
.data {
    display: flex;
    flex-direction: column;
    .item-row {
        flex:1;
        text-align: center;
        display:flex;
        vertical-align: middle;
        .item {
            padding:.625rem .625rem;
            flex:1;
            &.up {
                background: url("../assets/imgs/home_bg_up.png") no-repeat;
                background-size: 100%  100%;
            }
            &.down {
                background: url("../assets/imgs/home_bg_down.png") no-repeat;
                background-size: 100%  100%;
            }
            // &.up-left {
            //     background: url("../assets/imgs/home_bg_up_l.png") no-repeat;
            //     background-size: 100%  100%;
            // }
            // &.up-right {
            //     background: url("../assets/imgs/home_bg_up_r.png") no-repeat;
            //     background-size: 100%  100%;
            // }
            // &.down-left {
            //     background: url("../assets/imgs/home_bg_down_l.png") no-repeat;
            //     background-size: 100%  100%;
            // }
            // &.down-right {
            //     background: url("../assets/imgs/home_bg_down_r.png") no-repeat;
            //     background-size: 100%  100%;
            // }
            // background-attachment: fixed;
            .item-one {
                border-radius: 10px;
                padding:2.5rem 0;
                .title {
                    color:grey;
                    font-size: 12px;
                }
                display: flex;
                .item-one-left {
                    flex:2
                }
                .item-one-right {
                    flex: 1;
                    padding-top:.375rem;
                    box-sizing:content-box;
                }
            }

        }
    }
}
.add {
    position: fixed;
    bottom: 62px;
    width: 100%;
    .button {
        margin: 0 auto;
        width: 4rem;
        height: 4rem;
        .van-button--large {
            width: 100%;
            height: 100%;
        }
        .van-button__icon {
            font-size: 3rem;
        }
    }
}

</style>
