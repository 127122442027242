<template>
    <footer :class="commonClass">
        <ul>
            <router-link to="/home" custom v-slot="{navigate,isActive}" >
                <li @click="navigate" :class="isActive?'router-link-active':''">
                    <van-icon :name="isActive?'wap-home':'wap-home-o'" />
                    <span>首页</span>
                </li>
            </router-link>

            <router-link to="/merchants" custom v-slot="{navigate,isActive}">
                <li @click="navigate" :class="isActive?'router-link-active':''">
                    <van-icon :name="isActive?'friends':'friends-o'" />
                    <span>商户</span>
                </li>
            </router-link>
            <router-link to="/my" custom v-slot="{navigate,isActive}">
                <li @click="navigate" :class="isActive?'router-link-active':''">
                    <van-icon :name="isActive?'manager':'manager-o'"/>
                    <span>我的</span>
                </li>
            </router-link>
        </ul>
    </footer>
</template>
<script >
export default {
  props: {
    commonClass: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #eee;
}
.common {
    background: white !important;
    border-top: 1px solid #ccc;
}

ul {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    color:grey;
    li {
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        i {
            font-size: 20px;
        }
        span {
            font-size: 12px;
        }
    }
}

.router-link-active {
  color: $themeColor;
}
ul li span {
    font-size: $defaultFontSize;
}
</style>
